import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"

import Content from '..';

import { graphql } from "gatsby"
import Img from "gatsby-image"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import styled from 'styled-components';

const Image2 = styled(Img)`
    padding: 2.5px;
    border-radius: 25px;
    max-width: 500px;
    height: auto;

    @media (max-width: 1200px) {
        max-width: 100%
    }
`

const StyledIcon2 = styled(FontAwesomeIcon)`
    font-size: 18px;
    margin-left: 8px;
`;

const AboutDuoLivre = ({data}) => {
    return (
        <Layout>
            <SEO title="About DuoLivre, LLC"/>
            <Content.Container>
                <br/>
                <div align="center">
                    <div style={{ maxWidth: `500px`}}>
                        <Image2
                            title="Web developer sitting on top of her website."      
                            alt="Web developer sitting on top of her website."
                            fluid={data.file.childImageSharp.fluid}
                        />
                    </div>
                </div>
                <br/>
                <Content.Title>About DuoLivre, LLC</Content.Title>
                <br/>
                <Content.Text><b>Upshot:</b> DuoLivre, LLC, offers the following services:
                <br/><br/><ul>
                    <li>Website design and development</li>
                    <li>Monthly hosting</li>
                    <li>Add-ons such as:</li>
                    <ul>
                        <li>Website copy</li>
                        <li>An SEO starter-package (a plan for keywords to target)</li>
                        <li>Month-to-month SEO services</li>
                    </ul>
                </ul>
                </Content.Text>
                <br/>
                <Content.Text>
                    For more information, go to the official website: <a href="http://www.duolivre.com" target="_blank" rel="noopener noreferrer">duolivre.com</a><StyledIcon2 icon={faExternalLinkAlt}/>.
                </Content.Text>
                <br/>
                <Content.Text>Please note: we are not currently accepting new clients.</Content.Text>
                <br/>
            </Content.Container>
        </Layout>
    )
}

export default AboutDuoLivre

export const query = graphql`  
  query {    
    file(relativePath: {eq: "Freelance.png"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
